import React from "react";
import "./ComicImageModal.css";

const ComicImageModal = ({ showModal, setShowModal, imgSrc }) => {
    React.useEffect(() => {
        // When the modal is open, we disable scrolling
        if (showModal) {
          document.body.style.overflow = 'hidden';
        }
        // When the modal is closed, we re-enable scrolling
        return () => {
          document.body.style.overflow = 'unset';
        };
      }, [showModal]); // Only re-run the effect if showModal changes
  if (!showModal) return null;
  return (
    <div className="comic__image__modal" onClick={() => setShowModal(false)}>
      <div className="comic__image__modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={imgSrc} alt="Enlarged comic" />
      </div>
    </div>
  );
};

export default ComicImageModal;
