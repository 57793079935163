import React from "react";
import "./Comic.css";
import card from "../../assets/images/Picture1.jpg";
import ebayLogo from "../../assets/images/ebay__logo.png";
import ComicChart from "../../components/ComicChart/ComicChart";
import ComicImageModal from "../../components/ComicImageModal/ComicImageModal";

const Comic = () => {
  const [version, setVersion] = React.useState("Universal");
  const [expand, setExpand] = React.useState(false);
  const [grades, setGrades] = React.useState([10, 9.8, 9.4]);
  const [showModal, setShowModal] = React.useState(false);
  const [currentImg, setCurrentImg] = React.useState("");

  const gradeClicked = (grade) => {
    if (grades.includes(grade)) {
      setGrades(grades.filter((g) => g !== grade));
    } else {
      setGrades([...grades, grade]);
    }
  };

  const handleImageClick = (imgSrc) => {
    setCurrentImg(imgSrc);
    setShowModal(true);
  };

  return (
    <div className="comic">
      <div className="comic__container">
        <div className="comic__container__left">
          <div className="comic__container__left__image">
            <img
              className="comic__container__left__img"
              src={card}
              alt="comic"
            />
          </div>
          <div className="comic__container__left__watchlist">
            <button className="comic__container__left__watchlist__button">
              Add to Watchlist
            </button>
          </div>
          <div className="comic__container__left__ebay">
            <button className="comic__container__left__ebay__button">
              Buy it now
            </button>
            <div className="comic__container__left__ebay__icon">
              <img
                className="comic__container__left__ebay__icon__img"
                src={ebayLogo}
                alt="ebay"
              />
            </div>
          </div>
        </div>
        <div className="comic__container__right">
          <div className="comic__container__right__header">
            <div className="comic__container__right__header__title">
              Alien vs. Predator vs. the Terminator
            </div>
            <div className="comic__container__right__header__versions">
              <div
                className={
                  version === "Universal"
                    ? "comic__container__right__header__version__selected"
                    : "comic__container__right__header__version"
                }
                onClick={() => setVersion("Universal")}
              >
                Universal
              </div>
              <div
                className={
                  version === "Signature Series"
                    ? "comic__container__right__header__version__selected"
                    : "comic__container__right__header__version"
                }
                onClick={() => setVersion("Signature Series")}
              >
                Signature Series
              </div>
              <div
                className={
                  version === "Qualified"
                    ? "comic__container__right__header__version__selected"
                    : "comic__container__right__header__version"
                }
                onClick={() => setVersion("Qualified")}
              >
                Qualified
              </div>
              <div
                className={
                  version === "Restored"
                    ? "comic__container__right__header__version__selected"
                    : "comic__container__right__header__version"
                }
                onClick={() => setVersion("Restored")}
              >
                Restored
              </div>
            </div>
            <div className="comic__container__right__header__details">
              <div className="comic__container__right__header__details__left">
                <div
                  className="comic__container__right__header__details__left__box"
                  style={{ width: "60%" }}
                >
                  <div className="comic__container__right__header__details__left__box__title">
                    Issue No.:
                  </div>
                  <div className="comic__container__right__header__details__left__box__value">
                    #0
                  </div>
                </div>
                <div className="comic__container__right__header__details__left__box">
                  <div className="comic__container__right__header__details__left__box__title">
                    Issue Year:
                  </div>
                  <div className="comic__container__right__header__details__left__box__value">
                    2000
                  </div>
                </div>
                <div
                  className="comic__container__right__header__details__left__box"
                  style={{ width: "60%" }}
                >
                  <div className="comic__container__right__header__details__left__box__title">
                    Publisher:
                  </div>
                  <div className="comic__container__right__header__details__left__box__value">
                    Dark Horse Comics
                  </div>
                </div>
                <div className="comic__container__right__header__details__left__box">
                  <div className="comic__container__right__header__details__left__box__title">
                    Issue Date:
                  </div>
                  <div className="comic__container__right__header__details__left__box__value">
                    05 - Nov
                  </div>
                </div>
              </div>
              <div className="comic__container__right__header__details__right">
                <div className="comic__container__right__header__details__right__title">
                  CGC Art Comments:
                </div>
                <div className="comic__container__right__header__details__right__value">
                  Mark Schultz story, Mel Rubi & Christopher Ivy art, Dwayne
                  Turner cover
                </div>
              </div>
            </div>
          </div>
          <div className="comic__container__right__stats">
            <div className="comic__container__right__stats__left">
              <div className="comic__container__right__stats__left__items">
                <div className="comic__container__right__stats__left__item">
                  <div className="comic__container__right__stats__left__item__title">
                    CGC Total Population
                  </div>
                  <div className="comic__container__right__stats__left__item__value">
                    1232
                  </div>
                </div>
                <div className="comic__container__right__stats__left__item">
                  <div className="comic__container__right__stats__left__item__title">
                    Near Mint + Total
                  </div>
                  <div className="comic__container__right__stats__left__item__value">
                    1042
                  </div>
                </div>
              </div>
              <div className="comic__container__right__stats__left__total">
                <div className="comic__container__right__stats__left__item">
                  <div className="comic__container__right__stats__left__item__title">
                    Near Mint Rate
                  </div>
                  <div className="comic__container__right__stats__left__item__percentage">
                    89.2%
                  </div>
                </div>
              </div>
            </div>
            <div className="comic__container__right__stats__right">
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 10
                </div>
                <div className="comic__container__right__stats__right__value">
                  32
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 9.9
                </div>
                <div className="comic__container__right__stats__right__value">
                  32
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 9.8
                </div>
                <div className="comic__container__right__stats__right__value">
                  12
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 9.6
                </div>
                <div className="comic__container__right__stats__right__value">
                  8
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 9.4
                </div>
                <div className="comic__container__right__stats__right__value">
                  128
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 9.2
                </div>
                <div className="comic__container__right__stats__right__value">
                  360
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 9.0
                </div>
                <div className="comic__container__right__stats__right__value">
                  9621
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 8.5
                </div>
                <div className="comic__container__right__stats__right__value">
                  320
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 8.0
                </div>
                <div className="comic__container__right__stats__right__value">
                  532
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 7.5
                </div>
                <div className="comic__container__right__stats__right__value">
                  12
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 7.0
                </div>
                <div className="comic__container__right__stats__right__value">
                  108
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 6.5
                </div>
                <div className="comic__container__right__stats__right__value">
                  128
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 6.0
                </div>
                <div className="comic__container__right__stats__right__value">
                  3610
                </div>
              </div>
              <div className="comic__container__right__stats__right__grades">
                <div className="comic__container__right__stats__right__grade">
                  CGC 5.5
                </div>
                <div className="comic__container__right__stats__right__value">
                  89
                </div>
              </div>
              {expand ? (
                <>
                  <div className="comic__container__right__stats__right__grades">
                    <div className="comic__container__right__stats__right__grade">
                      CGC 5.0
                    </div>
                    <div className="comic__container__right__stats__right__value">
                      32
                    </div>
                  </div>
                  <div className="comic__container__right__stats__right__grades">
                    <div className="comic__container__right__stats__right__grade">
                      CGC 4.5
                    </div>
                    <div className="comic__container__right__stats__right__value">
                      32
                    </div>
                  </div>
                  <div className="comic__container__right__stats__right__grades">
                    <div className="comic__container__right__stats__right__grade">
                      CGC 4.0
                    </div>
                    <div className="comic__container__right__stats__right__value">
                      32
                    </div>
                  </div>
                  <div className="comic__container__right__stats__right__grades">
                    <div className="comic__container__right__stats__right__grade">
                      CGC 3.5
                    </div>
                    <div className="comic__container__right__stats__right__value">
                      32
                    </div>
                  </div>
                  <div className="comic__container__right__stats__right__grades">
                    <div className="comic__container__right__stats__right__grade">
                      CGC 3.0
                    </div>
                    <div className="comic__container__right__stats__right__value">
                      32
                    </div>
                  </div>
                  <div className="comic__container__right__stats__right__grades">
                    <div className="comic__container__right__stats__right__grade">
                      CGC 2.5
                    </div>
                    <div className="comic__container__right__stats__right__value">
                      32
                    </div>
                  </div>
                  <div className="comic__container__right__stats__right__grades">
                    <div className="comic__container__right__stats__right__grade">
                      CGC 2.0
                    </div>
                    <div className="comic__container__right__stats__right__value">
                      32
                    </div>
                  </div>
                  <div className="comic__container__right__stats__right__grades">
                    <div className="comic__container__right__stats__right__grade">
                      CGC 1.5
                    </div>
                    <div className="comic__container__right__stats__right__value">
                      32
                    </div>
                  </div>
                  <div className="comic__container__right__stats__right__grades">
                    <div className="comic__container__right__stats__right__grade">
                      CGC 1.0
                    </div>
                    <div className="comic__container__right__stats__right__value">
                      32
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="comic__container__right__stats__button">
              <button
                className="comic__container__right__stats__button__btn"
                onClick={() => setExpand(!expand)}
              >
                {expand ? "Click to Shrink" : "Click to Expand"}
              </button>
            </div>
          </div>
          <div className="comic__container__right__sales__summary">
            <div className="comic__container__right__sales__summary__title">
              Sales Summary Snapshot
            </div>
            <div className="comic__container__right__sales__summary__section">
              <div className="comic__container__right__sales__summary__section__table__grade__border"></div>
              <div className="comic__container__right__sales__summary__section__header">
                <div className="comic__container__right__sales__summary__section__header__row">
                  <div className="comic__container__right__sales__summary__section__header__row__title"></div>
                  <div className="comic__container__right__sales__summary__section__header__row__item">
                    <div className="comic__container__right__sales__summary__section__header__row__grade"></div>
                    <div className="comic__container__right__sales__summary__section__header__row__lsd">
                      Last Sold Date
                    </div>
                    <div className="comic__container__right__sales__summary__section__header__row__price">
                      Price
                    </div>
                    <div className="comic__container__right__sales__summary__section__header__row__past">
                      Past 3 Average
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__header__row__item">
                    <div className="comic__container__right__sales__summary__section__header__row__grade"></div>
                    <div className="comic__container__right__sales__summary__section__header__row__lsd">
                      Last Sold Date
                    </div>
                    <div className="comic__container__right__sales__summary__section__header__row__price">
                      Price
                    </div>
                    <div className="comic__container__right__sales__summary__section__header__row__past">
                      Past 3 Average
                    </div>
                  </div>
                </div>
              </div>
              <div className="comic__container__right__sales__summary__section__table">
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 10
                  </div>
                  {/* <div className="comic__container__right__sales__summary__section__table__row__item">
                        <div className="comic__container__right__sales__summary__section__table__row__grade">CGC 10</div>
                        <div className="comic__container__right__sales__summary__section__table__row__lsd">Jun-07-2023</div>
                        <div className="comic__container__right__sales__summary__section__table__row__price">$34.4</div>
                        <div className="comic__container__right__sales__summary__section__table__row__past">$34.4</div>
                    </div> */}
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 10
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 9
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 9.9
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 9.8
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__title"></div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 9.6
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 9.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__title"></div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 9.2
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 9.0
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 8
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 8.5
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 8.0
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 7
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 7.5
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 7.0
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 6
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 6.5
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 6.0
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 5
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 5.5
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 5.0
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 4
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 4.5
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 4.0
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 3
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 3.5
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 3.0
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 2
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 2.5
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 2.0
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
                <div className="comic__container__right__sales__summary__section__table__row">
                  <div className="comic__container__right__sales__summary__section__table__row__title">
                    Grade 1
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 1.5
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                  <div className="comic__container__right__sales__summary__section__table__row__item">
                    <div className="comic__container__right__sales__summary__section__table__row__grade">
                      CGC 1.0
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__lsd">
                      Jun-07-2023
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__price">
                      $34.4
                    </div>
                    <div className="comic__container__right__sales__summary__section__table__row__past">
                      $34.4
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="comic__container__right__historical__sales">
            <div className="comic__container__right__historical__sales__title">
              Historical Sales Information
            </div>
            <div className="comic__container__right__historical__sales__grade__selection">
              <div
                className={
                  grades.includes(10)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(10)}
              >
                10
              </div>
              <div
                className={
                  grades.includes(9.9)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(9.9)}
              >
                9.9
              </div>
              <div
                className={
                  grades.includes(9.8)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(9.8)}
              >
                9.8
              </div>
              <div
                className={
                  grades.includes(9.6)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(9.6)}
              >
                9.6
              </div>
              <div
                className={
                  grades.includes(9.4)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(9.4)}
              >
                9.4
              </div>
              <div
                className={
                  grades.includes(9.2)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(9.2)}
              >
                9.2
              </div>
              <div
                className={
                  grades.includes(9.0)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(9.0)}
              >
                9.0
              </div>
              <div
                className={
                  grades.includes(8.5)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(8.5)}
              >
                8.5
              </div>
              <div
                className={
                  grades.includes(8.0)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(8.0)}
              >
                8.0
              </div>
              <div
                className={
                  grades.includes(7.5)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(7.5)}
              >
                7.5
              </div>
              <div
                className={
                  grades.includes(7.0)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(7.0)}
              >
                7.0
              </div>
              <div
                className={
                  grades.includes(6.5)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(6.5)}
              >
                6.5
              </div>
              <div
                className={
                  grades.includes(6.0)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(6.0)}
              >
                6.0
              </div>
              <div
                className={
                  grades.includes(5.5)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(5.5)}
              >
                5.5
              </div>
              <div
                className={
                  grades.includes(5.0)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(5.0)}
              >
                5.0
              </div>
              <div
                className={
                  grades.includes(4.5)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(4.5)}
              >
                4.5
              </div>
              <div
                className={
                  grades.includes(4.0)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(4.0)}
              >
                4.0
              </div>
              <div
                className={
                  grades.includes(3.5)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(3.5)}
              >
                3.5
              </div>
              <div
                className={
                  grades.includes(3.0)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(3.0)}
              >
                3.0
              </div>
              <div
                className={
                  grades.includes(2.5)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(2.5)}
              >
                2.5
              </div>
              <div
                className={
                  grades.includes(2.0)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(2.0)}
              >
                2.0
              </div>
              <div
                className={
                  grades.includes(1.5)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(1.5)}
              >
                1.5
              </div>
              <div
                className={
                  grades.includes(1.0)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(1.0)}
              >
                1.0
              </div>
              <div
                className={
                  grades.includes(0.5)
                    ? "comic__container__right__historical__sales__grade__selected"
                    : "comic__container__right__historical__sales__grade"
                }
                onClick={() => gradeClicked(0.5)}
              >
                0.5
              </div>
            </div>
            <div className="comic__container__right__historical__sales__chart">
              <ComicChart />
            </div>
          </div>
          <div className="comic__container__right__sales__details">
            <div className="comic__container__right__sales__details__title">
              Sales Details
            </div>
            <div className="comic__container__right__sales__details__headings">
              <div className="comic__container__right__sales__details__headings__image">
                Image
              </div>
              <div className="comic__container__right__sales__details__headings__grade">
                Grade
              </div>
              <div className="comic__container__right__sales__details__headings__date">
                Date
              </div>
              <div className="comic__container__right__sales__details__headings__price">
                Price
              </div>
              <div className="comic__container__right__sales__details__headings__format">
                Format
              </div>
              <div className="comic__container__right__sales__details__headings__cn">
                Cert Number
              </div>
              <div className="comic__container__right__sales__details__headings__title">
                Listing Title
              </div>
              <div className="comic__container__right__sales__details__headings__platform">
                Platform
              </div>
            </div>
            <div className="comic__container__right__sales__details__table">
              <ComicImageModal
                showModal={showModal}
                setShowModal={setShowModal}
                imgSrc={currentImg}
              />
              <div className="comic__container__right__sales__details__table__row">
                {/* <div className="comic__container__right__sales__details__table__row__image">
                  <img src={card} alt="" />
                </div> */}
                <div
                  className="comic__container__right__sales__details__table__row__image"
                  onClick={() => handleImageClick(card)}
                >
                  <img src={card} alt="" />
                </div>
                <div className="comic__container__right__sales__details__table__row__grade">
                  CGC 10
                </div>
                <div className="comic__container__right__sales__details__table__row__date">
                  Jun-07-2023
                </div>
                <div className="comic__container__right__sales__details__table__row__price">
                  $34.4
                </div>
                <div className="comic__container__right__sales__details__table__row__format">
                  Auction
                </div>
                <div className="comic__container__right__sales__details__table__row__cn">
                  00000000
                </div>
                <div className="comic__container__right__sales__details__table__row__title">
                  1999 Pokemon CGC 10 Gem Mint Charizard Base Set Shadowless
                  Holo 4/102
                </div>
                <div className="comic__container__right__sales__details__table__row__platform">
                  PWCC
                </div>
              </div>
              <div className="comic__container__right__sales__details__table__row">
                <div
                  className="comic__container__right__sales__details__table__row__image"
                  onClick={() => handleImageClick(card)}
                >
                  <img src={card} alt="" />
                </div>
                <div className="comic__container__right__sales__details__table__row__grade">
                  CGC 9.6
                </div>
                <div className="comic__container__right__sales__details__table__row__date">
                  Jun-07-2023
                </div>
                <div className="comic__container__right__sales__details__table__row__price">
                  $34.4
                </div>
                <div className="comic__container__right__sales__details__table__row__format">
                  Fixed Price
                </div>
                <div className="comic__container__right__sales__details__table__row__cn">
                  00000000
                </div>
                <div className="comic__container__right__sales__details__table__row__title">
                  1999 Pokemon CGC 10 Gem Mint Charizard Base Set Shadowless
                  Holo 4/10
                </div>
                <div className="comic__container__right__sales__details__table__row__platform">
                  eBay
                </div>
              </div>
              <div className="comic__container__right__sales__details__table__row">
                <div
                  className="comic__container__right__sales__details__table__row__image"
                  onClick={() => handleImageClick(card)}
                >
                  <img src={card} alt="" />
                </div>
                <div className="comic__container__right__sales__details__table__row__grade">
                  CGC 10
                </div>
                <div className="comic__container__right__sales__details__table__row__date">
                  Jun-07-2023
                </div>
                <div className="comic__container__right__sales__details__table__row__price">
                  $34.4
                </div>
                <div className="comic__container__right__sales__details__table__row__format">
                  Auction
                </div>
                <div className="comic__container__right__sales__details__table__row__cn">
                  00000000
                </div>
                <div className="comic__container__right__sales__details__table__row__title">
                  1999 Pokemon CGC 10 Gem Mint Charizard Base Set Shadowless
                  Holo 4/102
                </div>
                <div className="comic__container__right__sales__details__table__row__platform">
                  PWCC
                </div>
              </div>
              <div className="comic__container__right__sales__details__table__row">
                <div
                  className="comic__container__right__sales__details__table__row__image"
                  onClick={() => handleImageClick(card)}
                >
                  <img src={card} alt="" />
                </div>
                <div className="comic__container__right__sales__details__table__row__grade">
                  CGC 9.6
                </div>
                <div className="comic__container__right__sales__details__table__row__date">
                  Jun-07-2023
                </div>
                <div className="comic__container__right__sales__details__table__row__price">
                  $34.4
                </div>
                <div className="comic__container__right__sales__details__table__row__format">
                  Fixed Price
                </div>
                <div className="comic__container__right__sales__details__table__row__cn">
                  00000000
                </div>
                <div className="comic__container__right__sales__details__table__row__title">
                  1999 Pokemon CGC 10 Gem Mint Charizard Base Set Shadowless
                  Holo 4/10
                </div>
                <div className="comic__container__right__sales__details__table__row__platform">
                  eBay
                </div>
              </div>
              <div className="comic__container__right__sales__details__table__row">
                <div
                  className="comic__container__right__sales__details__table__row__image"
                  onClick={() => handleImageClick(card)}
                >
                  <img src={card} alt="" />
                </div>
                <div className="comic__container__right__sales__details__table__row__grade">
                  CGC 10
                </div>
                <div className="comic__container__right__sales__details__table__row__date">
                  Jun-07-2023
                </div>
                <div className="comic__container__right__sales__details__table__row__price">
                  $34.4
                </div>
                <div className="comic__container__right__sales__details__table__row__format">
                  Auction
                </div>
                <div className="comic__container__right__sales__details__table__row__cn">
                  00000000
                </div>
                <div className="comic__container__right__sales__details__table__row__title">
                  1999 Pokemon CGC 10 Gem Mint Charizard Base Set Shadowless
                  Holo 4/102
                </div>
                <div className="comic__container__right__sales__details__table__row__platform">
                  PWCC
                </div>
              </div>
              <div className="comic__container__right__sales__details__table__row">
                <div
                  className="comic__container__right__sales__details__table__row__image"
                  onClick={() => handleImageClick(card)}
                >
                  <img src={card} alt="" />
                </div>
                <div className="comic__container__right__sales__details__table__row__grade">
                  CGC 9.6
                </div>
                <div className="comic__container__right__sales__details__table__row__date">
                  Jun-07-2023
                </div>
                <div className="comic__container__right__sales__details__table__row__price">
                  $34.4
                </div>
                <div className="comic__container__right__sales__details__table__row__format">
                  Fixed Price
                </div>
                <div className="comic__container__right__sales__details__table__row__cn">
                  00000000
                </div>
                <div className="comic__container__right__sales__details__table__row__title">
                  1999 Pokemon CGC 10 Gem Mint Charizard Base Set Shadowless
                  Holo 4/10
                </div>
                <div className="comic__container__right__sales__details__table__row__platform">
                  eBay
                </div>
              </div>
              <div className="comic__container__right__sales__details__table__row">
                <div
                  className="comic__container__right__sales__details__table__row__image"
                  onClick={() => handleImageClick(card)}
                >
                  <img src={card} alt="" />
                </div>
                <div className="comic__container__right__sales__details__table__row__grade">
                  CGC 10
                </div>
                <div className="comic__container__right__sales__details__table__row__date">
                  Jun-07-2023
                </div>
                <div className="comic__container__right__sales__details__table__row__price">
                  $34.4
                </div>
                <div className="comic__container__right__sales__details__table__row__format">
                  Auction
                </div>
                <div className="comic__container__right__sales__details__table__row__cn">
                  00000000
                </div>
                <div className="comic__container__right__sales__details__table__row__title">
                  1999 Pokemon CGC 10 Gem Mint Charizard Base Set Shadowless
                  Holo 4/102
                </div>
                <div className="comic__container__right__sales__details__table__row__platform">
                  PWCC
                </div>
              </div>
              <div className="comic__container__right__sales__details__table__row">
                <div
                  className="comic__container__right__sales__details__table__row__image"
                  onClick={() => handleImageClick(card)}
                >
                  <img src={card} alt="" />
                </div>
                <div className="comic__container__right__sales__details__table__row__grade">
                  CGC 9.6
                </div>
                <div className="comic__container__right__sales__details__table__row__date">
                  Jun-07-2023
                </div>
                <div className="comic__container__right__sales__details__table__row__price">
                  $34.4
                </div>
                <div className="comic__container__right__sales__details__table__row__format">
                  Fixed Price
                </div>
                <div className="comic__container__right__sales__details__table__row__cn">
                  00000000
                </div>
                <div className="comic__container__right__sales__details__table__row__title">
                  1999 Pokemon CGC 10 Gem Mint Charizard Base Set Shadowless
                  Holo 4/10
                </div>
                <div className="comic__container__right__sales__details__table__row__platform">
                  eBay
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comic;
